<!--video width="100%" playsinline autoplay muted loop>
    <source src="../../assets/hero/party-purple.mp4" type="video/mp4">
    Your browser does not support HTML5 video.
  </video -->
  <div class="container">
    <div class="tag">Simpel. Uniek. Versterkt.
                      <span><p> Versterk je merk.
                        Bereik Meer Klanten.</p></span>
                     <button class='maincta mat-elevation-z8'  mat-raised-button color="accent" aria-label="cta">Soundlogo maken</button>
                </div>
    <img src="../../assets/hero/grey_transistor_radio-scopio-8b14e7e1-a5a8-4404-892a-ff78f2dd027c.jpg" >
    
</div>
<a href='#why' mat-mini-fab class="scrolldown"><mat-icon>keyboard_arrow_down
</mat-icon></a>
