<app-hero></app-hero>
<div class="explanation" style="background-color:black;">
    <a id="why"></a>

    <div class='why'>
        <h1>In 3 stappen een uniek geluid voor uw merk.</h1>

        <div fxLayoutAlign="center center" ><h2>Stap 1</h2>
        <p>Vertel wat je wilt uitstralen</p></div>
        <div fxLayoutAlign="center center" ><h2>Stap 2</h2><p>Vertel naar wie je het wilt uitstralen </p>
            </div>
        <div fxLayoutAlign="center center" ><h2>Stap 3</h2><p>Wij zetten de juiste toon </p>
        </div>

       
    </div>
</div>
<div class="explanation" style="background-color:green;">

    <a id="how"></a>
    <h2>How it works</h2>
AI en menselijke expertise in combinatie
</div>
<div class="explanation" style="background-color:orange;">

<a id="contact"></a>
<h2>Get in touch</h2>

Email box + subscribe
<!-- div class='imgleft'>
    <div>  <img src="../../assets/img/hearing.png" /></div> 
<div><h3>Hearing</h3>
    <p>Did you know that hearing is the most important sense when it comes to building lasting memories?</p></div>
</div -->
</div>