<!--mat-toolbar color="primary" --><span class="logofixed">
 <svg width="76.727mm" height="13.987mm" version="1.1" viewBox="0 0 76.727 13.987" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-24.051 -88.129)" fill-opacity=".99608">
         <g class='logo-text' fill="#00000f" stroke-width=".26458" aria-label="sound">
          <path d="m26.196 91.727q0.56444 0 0.95956 0.18344 0.39511 0.18344 0.57856 0.33867 0.46567 0.381 0.56444-0.508h0.32456q-0.02822 0.39511-0.04233 0.94544-0.01411 0.53622-0.01411 1.4534h-0.32456q-0.08467-0.52211-0.29633-1.016-0.19756-0.49389-0.59267-0.80433-0.39511-0.32456-1.0301-0.32456-0.49389 0-0.83256 0.28222-0.32456 0.26811-0.32456 0.80433 0 0.42333 0.254 0.73378 0.254 0.29633 0.66322 0.56444 0.40922 0.254 0.889 0.56444 0.80433 0.52211 1.3688 1.0583 0.56444 0.53622 0.56444 1.4111 0 0.64911-0.33867 1.0866-0.33867 0.43744-0.889 0.66322-0.53622 0.22578-1.1853 0.22578-0.31044 0-0.59267-0.05644-0.26811-0.04233-0.52211-0.14111-0.14111-0.07056-0.29633-0.16933-0.14111-0.09878-0.28222-0.21167-0.14111-0.11289-0.254 0.01411-0.11289 0.11289-0.16933 0.46567h-0.32456q0.02822-0.45156 0.04233-1.1007t0.01411-1.7216h0.32456q0.09878 0.79022 0.28222 1.3829 0.18344 0.59267 0.57856 0.93133 0.39511 0.32456 1.1007 0.32456 0.42333 0 0.83256-0.29633 0.42333-0.29633 0.42333-1.0442 0-0.62089-0.45156-1.016t-1.143-0.83256q-0.508-0.32456-0.95956-0.64911-0.43744-0.32456-0.71967-0.73378-0.26811-0.40922-0.26811-0.98778 0-0.635 0.28222-1.0301 0.29633-0.40922 0.77611-0.59267 0.47978-0.19756 1.0301-0.19756z"/>
          <path d="m33.438 91.727q0.90311 0 1.6228 0.39511t1.143 1.2418q0.43744 0.84667 0.43744 2.2013 0 1.3547-0.43744 2.2013-0.42333 0.83256-1.143 1.2277-0.71967 0.39511-1.6228 0.39511-0.889 0-1.6228-0.39511-0.71967-0.39511-1.1571-1.2277-0.42333-0.84667-0.42333-2.2013 0-1.3547 0.42333-2.2013 0.43744-0.84667 1.1571-1.2418 0.73378-0.39511 1.6228-0.39511zm0 0.28222q-0.80433 0-1.3264 0.83256-0.508 0.83256-0.508 2.7234 0 1.8909 0.508 2.7234 0.52211 0.81844 1.3264 0.81844 0.80433 0 1.3123-0.81844 0.52211-0.83256 0.52211-2.7234 0-1.8909-0.52211-2.7234-0.508-0.83256-1.3123-0.83256z"/>
          <path d="m44.132 91.769v5.8984q0 0.635 0.22578 0.94544 0.23989 0.29633 0.84667 0.29633v0.29633q-0.43744-0.04233-0.87489-0.04233-0.40922 0-0.79022 0.04233-0.36689 0.04233-0.67733 0.15522v-1.6651q-0.35278 0.91722-1.0019 1.3123-0.635 0.381-1.3123 0.381-0.49389 0-0.86078-0.14111-0.36689-0.14111-0.60678-0.39511-0.26811-0.28222-0.36689-0.73378-0.09878-0.45156-0.09878-1.1148v-3.5419q0-0.635-0.22578-0.93133-0.22578-0.31044-0.84667-0.31044v-0.29633q0.45156 0.04233 0.87489 0.04233 0.40922 0 0.77611-0.04233 0.381-0.05644 0.69144-0.15522v5.5457q0 0.43744 0.05644 0.80433 0.05645 0.36689 0.28222 0.59267 0.22578 0.22578 0.73378 0.22578 0.83256 0 1.3688-0.73378 0.53622-0.74789 0.53622-1.8203v-2.921q0-0.635-0.22578-0.93133-0.22578-0.31044-0.84667-0.31044v-0.29633q0.45156 0.04233 0.87489 0.04233 0.40922 0 0.77611-0.04233 0.381-0.05644 0.69144-0.15522z"/>
          <path d="m50.653 91.727q0.53622 0 0.889 0.14111 0.36689 0.14111 0.60678 0.39511 0.26811 0.28222 0.381 0.70556t0.11289 1.143v3.7677q0 0.59267 0.23989 0.80433 0.254 0.21167 0.83256 0.21167v0.29633q-0.23989-0.01411-0.73378-0.02822-0.49389-0.02822-0.95956-0.02822-0.46567 0-0.91722 0.02822-0.43744 0.01411-0.66322 0.02822v-0.29633q0.508 0 0.71967-0.21167 0.21167-0.21167 0.21167-0.80433v-4.0781q0-0.43744-0.07056-0.80433-0.07055-0.36689-0.32456-0.59267-0.23989-0.22578-0.74789-0.22578-0.889 0-1.4393 0.74789-0.53622 0.73378-0.53622 1.8203v3.1327q0 0.59267 0.21167 0.80433 0.21167 0.21167 0.71967 0.21167v0.29633q-0.22578-0.01411-0.67733-0.02822-0.43744-0.02822-0.90311-0.02822t-0.95956 0.02822q-0.49389 0.01411-0.73378 0.02822v-0.29633q0.57856 0 0.81844-0.21167 0.254-0.21167 0.254-0.80433v-4.4168q0-0.635-0.22578-0.93133-0.22578-0.31044-0.84667-0.31044v-0.29633q0.45156 0.04233 0.87489 0.04233 0.40922 0 0.77611-0.04233 0.381-0.05644 0.69144-0.15522v1.6651q0.36689-0.95956 1.016-1.3264 0.66322-0.381 1.3829-0.381z"/>
          <path d="m60.765 88.129v9.525q0 0.635 0.22578 0.94544 0.23989 0.29633 0.84667 0.29633v0.29633q-0.43744-0.04233-0.87489-0.04233-0.40922 0-0.79022 0.04233-0.36689 0.04233-0.67733 0.15522v-9.525q0-0.635-0.22578-0.93133-0.22578-0.31044-0.84667-0.31044v-0.29633q0.45156 0.04233 0.87489 0.04233 0.40922 0 0.77611-0.04233 0.381-0.05644 0.69144-0.15522zm-2.9633 3.5983q0.62089 0 1.1571 0.29633 0.55033 0.29633 0.73378 1.016l-0.18344 0.14111q-0.19756-0.59267-0.62089-0.86078-0.40922-0.26811-0.97367-0.26811-0.83256 0-1.397 0.86078-0.55033 0.86078-0.53622 2.6529 0 1.143 0.21167 1.905 0.22578 0.74789 0.64911 1.1289 0.43744 0.36689 1.0583 0.36689 0.59267 0 1.0724-0.508 0.47978-0.508 0.57856-1.4958l0.16933 0.19756q-0.11289 1.0724-0.69144 1.651-0.56444 0.57856-1.5381 0.57856-0.87489 0-1.5381-0.42333-0.64911-0.42333-1.0019-1.27-0.33867-0.84667-0.33867-2.1308t0.43744-2.1308q0.45156-0.86078 1.1712-1.2841 0.73378-0.42333 1.5804-0.42333z"/>
         </g>
         <g class='logo-text' fill="#00000f" stroke-width=".26458" aria-label="hacker">
          <path d="m64.803 98.188h-1.0687v-3.6153q0-0.4377-0.05116-0.81856-0.05116-0.38654-0.18759-0.60255-0.14211-0.23875-0.40928-0.35244-0.26717-0.11937-0.6935-0.11937-0.4377 0-0.91519 0.21601-0.47749 0.21601-0.91519 0.55139v4.7408h-1.0687v-8.845h1.0687v3.2003q0.50023-0.41496 1.0346-0.64802t1.0971-0.23306q1.0289 0 1.5689 0.6196t0.54002 1.7849z"/>
          <path d="m71.795 98.188h-1.063v-0.67645q-0.14211 0.09664-0.38654 0.27285-0.23875 0.17053-0.46612 0.27285-0.26717 0.13074-0.61392 0.21601-0.34675 0.09095-0.81287 0.09095-0.85835 0-1.4552-0.56844-0.59686-0.56844-0.59686-1.4495 0-0.72192 0.30696-1.1653 0.31264-0.44907 0.88677-0.70487 0.57981-0.2558 1.3927-0.34675t1.7451-0.13643v-0.16485q0-0.3638-0.13074-0.60255-0.12506-0.23875-0.3638-0.37517-0.22738-0.13074-0.5457-0.17622-0.31833-0.04547-0.66508-0.04547-0.42065 0-0.93793 0.11369-0.51728 0.108-1.0687 0.31833h-0.05684v-1.0857q0.31264-0.08527 0.90382-0.18759 0.59118-0.10232 1.1653-0.10232 0.67076 0 1.1653 0.11369 0.50023 0.108 0.86403 0.37517 0.35812 0.26148 0.5457 0.67645t0.18759 1.0289zm-1.063-1.5632v-1.7679q-0.48886 0.02842-1.1539 0.08527-0.65939 0.05684-1.0459 0.16485-0.46044 0.13074-0.74466 0.40928-0.28422 0.27285-0.28422 0.75603 0 0.5457 0.3297 0.82424 0.3297 0.27285 1.0061 0.27285 0.56276 0 1.0289-0.21601 0.46612-0.22169 0.86403-0.52865z"/>
          <path d="m78.56 97.79q-0.53434 0.2558-1.0175 0.39791-0.47749 0.14211-1.0175 0.14211-0.68782 0-1.2619-0.19896-0.57413-0.20464-0.98341-0.61392-0.41496-0.40928-0.64234-1.0346t-0.22738-1.4609q0-1.5575 0.85266-2.4443 0.85835-0.88677 2.2624-0.88677 0.5457 0 1.0687 0.15348 0.52865 0.15348 0.96635 0.37517v1.188h-0.05684q-0.48886-0.38086-1.0118-0.5855-0.51728-0.20464-1.0118-0.20464-0.90951 0-1.4382 0.61392-0.52297 0.60823-0.52297 1.7906 0 1.1483 0.5116 1.7679 0.51728 0.61392 1.4495 0.61392 0.32401 0 0.65939-0.08527t0.60255-0.22169q0.23306-0.11937 0.4377-0.25012 0.20464-0.13643 0.32401-0.23306h0.05684z"/>
          <path d="m85.699 98.188h-1.4097l-2.5466-2.7797-0.6935 0.65939v2.1203h-1.0687v-8.845h1.0687v5.6731l3.0866-3.1776h1.3472l-2.9502 2.9332z"/>
          <path d="m92.14 95.124h-4.6783q0 0.5855 0.17622 1.0232 0.17622 0.43202 0.48318 0.71055 0.29559 0.27285 0.69918 0.40928 0.40928 0.13643 0.89814 0.13643 0.64802 0 1.3017-0.2558 0.65939-0.26148 0.93793-0.5116h0.05684v1.1653q-0.54002 0.22738-1.1028 0.38086-0.56276 0.15348-1.1824 0.15348-1.5803 0-2.467-0.85266-0.88677-0.85835-0.88677-2.4329 0-1.5575 0.84698-2.4727 0.85266-0.91519 2.2397-0.91519 1.2847 0 1.9782 0.75034 0.69918 0.75034 0.69918 2.1317zm-1.0402-0.81856q-0.0057-0.8413-0.42633-1.3017-0.41496-0.46044-1.2676-0.46044-0.85835 0-1.3699 0.50591-0.50591 0.50591-0.57413 1.2563z"/>
          <path d="m97.722 93.004h-0.05684q-0.23875-0.05685-0.46612-0.07958-0.22169-0.02842-0.52865-0.02842-0.49454 0-0.95498 0.22169-0.46044 0.21601-0.88677 0.56276v4.5077h-1.0687v-6.3495h1.0687v0.93793q0.63666-0.5116 1.1198-0.72192 0.48886-0.21601 0.99477-0.21601 0.27854 0 0.40359 0.01705 0.12506 0.01137 0.37517 0.05116z"/>
         </g>
         <path class="sound" d="m61.767 99.051 33.322-0.10161 0.50796-1.5239 0.60955 2.8445 0.81273-4.5716 0.81273 6.4002 0.50796-4.47 0.30477 1.6255 0.20318-1.0159h1.9302" fill="#66000000" stroke="#000" stroke-width=".26458px"/>
         <path transform="matrix(.26458 0 0 .26458 24.051 88.129)" d="m278.37 51.381c-0.11904-0.79193-0.67091-5.2411-1.2264-9.8871-0.55548-4.646-1.1391-8.7782-1.2969-9.1827-0.18661-0.47834-0.72661 1.7365-1.5447 6.3354-0.69176 3.889-1.3288 7.1429-1.4155 7.2309-0.33068 0.33533-0.53988-0.31664-1.351-4.2103-0.45978-2.2072-0.96915-4.1622-1.1319-4.3444-0.16279-0.18229-0.6017 0.63843-0.97537 1.8238l-0.67938 2.1553h-36.535c-20.094 0-48.486 0.11478-63.092 0.25506-20.093 0.19298-26.557 0.14625-26.557-0.19198 0-0.33578 6.6258-0.44704 26.622-0.44704 14.642 0 42.957-0.11474 62.923-0.25497l36.3-0.25497 0.88339-2.8168c0.48585-1.5492 1.0033-2.8168 1.1498-2.8168 0.14656 0 0.66384 2.0221 1.1495 4.4935 0.63314 3.2217 0.97047 4.2813 1.1919 3.7437 0.16984-0.41241 0.84005-3.7676 1.4894-7.456 1.129-6.4131 1.3339-7.2536 1.6809-6.8927 0.0846 0.08799 0.72271 4.6104 1.4181 10.05 0.69538 5.4393 1.3768 9.759 1.5143 9.5991s0.45559-2.1912 0.70686-4.5142c0.74514-6.889 0.89794-7.8713 1.2244-7.8713 0.17103 0 0.42757 0.77753 0.57007 1.7278 0.2583 1.7224 0.93985 2.4185 0.93985 0.95991 0-0.71404 0.25598-0.76793 3.6477-0.76793 2.1758 0 3.6477 0.15493 3.6477 0.38396 0 0.22717-1.3933 0.38397-3.4121 0.38397h-3.4121l-0.25911 1.7278c-0.32597 2.1738-0.82832 2.2141-1.1949 0.09599-0.25614-1.4799-0.30968-1.5425-0.57493-0.67194-0.16087 0.52795-0.58953 3.6813-0.95258 7.0074-0.36304 3.3261-0.78857 6.0475-0.9456 6.0475-0.15704 0-0.38291-0.64794-0.50195-1.4399z" fill="#000000" opacity="0"/>
         <!--circle r="1" fill="red">
            <animateMotion dur="1.6s" repeatCount="1">
                <mpath xlink:href="#sound"></mpath>
            </animateMotion>
        </circle -->
        
        </g>

       
       </svg></span>
       
    <!--/mat-toolbar -->    
